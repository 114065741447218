import React from 'react';
import { PostTitle, MainContent } from '../styles/base';

export const HomeFixedContent = () =>
  <MainContent>
    <PostTitle className="welcome">Tecnologia, liderança e inovação com propósito</PostTitle>
    <p>Bem-vindo ao meu espaço! Sou José Luiz Coe, líder em tecnologia, com mais de 20 anos de experiência criando soluções que conectam pessoas, negócios e resultados. Atuo remotamente, liderando times multidisciplinares para entregar produtos digitais eficientes e de alto impacto.</p>
    <p>Minha jornada profissional inclui passagens por empresas como UOL, GetNinjas, Ironhack, Olist e Loggi, onde venho alinhando negócio, tecnologia, design e produto para resolver problemas complexos de forma escalável e inovadora.</p>
    <p>Além do trabalho, sou atleta master de natação, pai do Bento e um entusiasta por temas como paternidade, design, fotografia e, claro, memes e piadas ruins – porque nem tudo precisa ser sério. :)</p>
    <p>Aqui você encontrará reflexões, projetos e inspirações que moldam meu dia a dia como profissional e pessoa.</p>
    <p>Explore, conecte-se e inspire-se!</p>
  </MainContent>

export default HomeFixedContent
